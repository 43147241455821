<template>
  <section id="member_forget_password">
    <div class="sub"
      :style="{backgroundImage: `url(${require('@/assets/img/member/bg_1.jpg')})`}">
      <!-- <div class="sub_box">
        <div class="main_title txt-center txt-white">
          <h3 class="eng">new member</h3>
          <h5>
            還沒成為會員？
            <img class="deco_flower" src="@/assets/img/deco_flower_white.svg" alt="">
          </h5>
        </div>

        <router-link to="/Signup" class="btn btn-white">
          立即加入
        </router-link>
      </div> -->
    </div>
    <div class="main">
        <div class="forget_box">
            <div class="main_title txt-center">
              <h3 class="eng">FORGET PASSWORD</h3>
              <h5>
                {{$t('fogotPasswordTitle')}}
                <img class="deco_flower" src="@/assets/img/deco_flower.svg" alt="">
              </h5>
            </div>
            <p class="small txt-dark_gray txt-center"
            :style="{textAlign: txt}"
            >
              {{$t('fogotPasswordInfo')}}
            </p>
            <form id="forget_form" name="forget_form" class="small">
                <input type="text" name="acc" value="" id="acc"
                  :placeholder="this.$t('customSWALInputAccount')"
                  v-model="formData.account">
                <date-pick
                  :selectableYearRange="{from: 1940, to: new Date().getUTCFullYear()}"
                  :inputAttributes="{
                    id: 'birthday',
                    name:'birthday',
                    placeholder:this.$t('pleaseInputBirthDay'),
                    readonly: true
                  }"
                  v-model="formData.birthday">
                </date-pick>

                <div class="btnbox">
                  <button type="button"
                    class="btn btn-dark p"
                    @click="sendForgetPassword(2)">
                    {{$t('resetPasswordTextMail')}}
                  </button>
                  <button type="button"
                    class="btn btn-dark p"
                    @click="sendForgetPassword(1)">
                    {{$t('resetPasswordEmail')}}
                  </button>
                </div>
                <div class="remember txt-dark_gray">
                  <p class="small">{{$t('hasAccount')}}</p>
                  <router-link to="/login" class="txt-blue small">{{$t('login')}}></router-link>
                </div>
            </form>
        </div>
    </div>

    <div id="forget_password_lightbox"
      v-show="lightboxOpen">
      <div class="cover" @click="clickCloseLightbox()"></div>
      <div class="box">
        <div class="text txt-center">
          <img class="deco_check" src="@/assets/img/member/carry_out.svg" alt="">
          <h5 class="txt-bold">
            {{lightboxMessage.title}}
            <img class="deco_flower" src="@/assets/img/deco_flower.svg" alt="">
          </h5>
          <p class="small txt-dark_gray"
            v-html="lightboxMessage.info">
          </p>
          <button type="button"
            class="btn btn-dark_solid p"
            @click="clickCloseLightbox()">
            {{$t('complete')}}
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import '@/assets/scss/login.scss';

import DatePick from 'vue-date-pick';
import 'vue-date-pick/dist/vueDatePick.css';

import { resetPassword } from '@/lib/public';
import { mapGetters } from 'vuex';

export default {
  name: 'ForgetPassword',
  components: { DatePick },
  data() {
    return {
      lightboxOpen: false,
      lightboxMessage: {
        title: '',
        info: '',
      },
      formData: {
        account: '',
        birthday: '',
      },
      txt: 'center',
    };
  },
  computed: {
    ...mapGetters([
      'getLanguage',
    ]),
  },
  methods: {
    sendForgetPassword(sendWay) {
      // const submitData = this.formData;
      const submitData = { ...this.formData, send_way: sendWay };
      if (submitData.account === '' || submitData.account === null) {
        this.$customSWAL({ icon: 'error', title: this.$t('customSWALInputAccount') });
      } else if (submitData.birthday === '' || submitData.birthday === null) {
        this.$customSWAL({ icon: 'error', title: this.$t('pleaseInputBirthDay') });
      } else {
        resetPassword(
          submitData,
          // 成功
          () => {
            console.log('sendWay', sendWay);
            // sendWay 1: 信件, 2: 簡訊
            if (sendWay === 1) {
              console.log('sendWay1', sendWay);
              this.openLightbox(
                this.$t('mailSendingTitle'),
                this.$t('mailSendingContent'),
              );
            } else if (sendWay === 2) {
              console.log('sendWay2', sendWay);
              this.openLightbox(
                this.$t('textMailSendingTitle'),
                this.$t('textMailSendingContent'),
              );
            }
          },
          // 失敗
          () => {
            this.$customSWAL({ icon: 'error', title: this.$t('memberNotFound') });
          },
        );
      }
      // this.axios.post(
      //   `${process.env.VUE_APP_API_KEY}auth/forget`,
      //   submitData,
      // ).then((response) => {
      //   // success callback
      //   console.log('success', response);
      // }, (response) => {
      //   // error callback
      //   console.log('error', response);
      // });
    },
    openLightbox(t, i) {
      this.lightboxOpen = true;
      this.lightboxMessage.title = t;
      this.lightboxMessage.info = i;
    },
    clickCloseLightbox() {
      this.lightboxOpen = false;
      this.$router.push({ path: '/login' });
    },
  },
  mounted() {
    if (this.getLanguage === 'en-US') {
      this.txt = 'left';
    }
  },
};
</script>
